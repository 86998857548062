@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  margin: 0 !important;
}


.App{
  
  height:100vh-48px;
  background-color: #efece7;
  background-image: linear-gradient(rgba(112,112,112,.3137254902) 1px,transparent 1px),linear-gradient(to right,rgba(112,112,112,.3137254902) 1px,#efece7 1px);
  background-size: 64px 64px;
  background-repeat: repeat;
}


/* *{
  outline: auto;
} */